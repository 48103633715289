<template>
    <footer class="position-fixed bottom-0 w-100 p-2 bg-secondary text-light mb-0 mt-1">

        <div class="mx-1 row row-cols-1 row-cols-sm-1 small">
            <div class="col-12">
                <p v-html="G.footerPowered" class="m-0"></p>
            </div>
        </div>

    </footer>
</template>


<script>
import { G } from '@/_common';

export default {
    name: 'FooterSimple',
    setup() {
        return { G };
    },
}
</script>