import axios from 'axios'
import NProgress from 'nprogress';
import router from "@/router";


export const G = {
    // ajaxBaseUrl: `http://127.0.0.1:8000`,
    ajaxBaseUrl: `https://qrbackend.sintez.az`,

    projectName: "QR Generator",
    footerPowered: 'Powered with <span class="text-danger">&#9829;</span> By <a href="https://sintez.az" class="footerClass mx-2 fw-bold" target="_blank">SINTEZ.AZ</a>  © 2023',
    footerDescription: 'This is free QR Code Generator for everyone',

    uniqueId: (symlen) => {
        symlen = symlen ? symlen : 4;
        // if( arguments.length && arguments[0] ) symlen = arguments[0];
        // else symlen = 4;
        var symsize, pos, id = '', sym = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        symsize = sym.length;
        for (var i = 1; i <= symlen; i++) {
            pos = Math.floor(Math.random() * symsize);
            id += sym[pos];
        }
        return id;
    },

    // capitalize: (txt) => {
    //     return txt
    //         .trim()
    //         .toLowerCase()
    //         .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
    // },
    capitalize: str => str.charAt(0).toUpperCase() + str.slice(1),

    stripTags: str => str != '' ? str.replace(/(<[^>]*>)*/gm, '') : '',

    strip: (str, sym) => str != '' ? str.replace(new RegExp(`^${sym}+`, 'i'), '').replace(new RegExp(`${sym}+$`, 'i'), '') : '',

    token_data: () => {
        if (G.hasClientData("AUTH_TOKEN"))
            return JSON.parse(atob(G.getClientData('AUTH_TOKEN').split('.')[0]));
        else
            return {};
    },

    getByWordCount: (text, count) => {
        count = count !== undefined && count > 0 ? count : 12;
        let b = '';
        if(text.length){
            let a = text.split(' ');
            if(a.length > count) b = a.filter((e, i) => i < count).join(' ') + ' ...';
            else b = text;
        }
        return  b.length ? b : '';
    },

    formatLocale: (value) => {
        if (value)
            return value.toLocaleString("az-AZ", {
                style: "currency",
                currency: "AZN",
                hour12: false,
                hourCycle: 'h24',
                // weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        else
            return value;
    },

    formatDateLocale: (value) => {
        if (value)
            return value.toLocaleDateString("az-AZ", {
                // style: "currency",
                // currency: "AZN",
                hour12: false,
                hourCycle: 'h24',
                // weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        else
            return value;
    },

    returnLocaleDate: (datestr, separator = '.') => {
        let ds = datestr.split(separator);

        let yearlit = {
            "00": "cü",
            "10": "cu",
            "20": "ci",
            "30": "cu",
            "40": "cı",
            "50": "ci",
            "60": "cı",
            "70": "ci",
            "80": "ci",
            "90": "cı",
        };


        let daylit = {
            "1": "ci",
            "2": "ci",
            "3": "cü",
            "4": "cü",
            "5": "ci",
            "6": "cı",
            "7": "ci",
            "8": "ci",
            "9": "cu",
            // "0": "cu",
        };
        let dayadvlit = {
            "10": "da",
            "20": "də",
            "30": "da",
        };
        // let day = str(int(datestr.strftime("%d")));
        let day = parseInt(ds[0]).toString();

        let monlit = {
            "1": "yanvar",
            "2": "fevral",
            "3": "mart",
            "4": "aprel",
            "5": "may",
            "6": "iyun",
            "7": "iyul",
            "8": "avqust",
            "9": "sentyabr",
            "10": "oktyabr",
            "11": "noyabr",
            "12": "dekabr",
        };

        let monadvlit = {
            "1": "ın",
            "2": "ın",
            "3": "ın",
            "4": "in",
            "5": "ın",
            "6": "un",
            "7": "un",
            "8": "un",
            "9": "ın",
            "10": "ın",
            "11": "ın",
            "12": "ın",
        };

        let month = parseInt(ds[1]).toString();
        let year = ds[2];

        // let lit = daylit[year[-1]] if year[-1] != '0' else yearlit['60']
        let lit = '';
        if( year.slice(-1) != '0')
            lit = daylit[year.slice(-1)];
        else
            lit = yearlit[year.slice(-2)];

        // return f'{year}-{lit} il {monlit[month]}ın {day}-' + ('da' if day[-1] in ['6','9'] else 'də')
        return `${year}-${lit} il ${monlit[month]}${monadvlit[month]} ${day}-` + ( dayadvlit[day.slice(-2)] !== undefined ? dayadvlit[day.slice(-2)] : (['6','9'].indexOf(day.slice(-1)) != -1 ? 'da' : 'də') )
    },

    getErrorMessages: (data) => {
        let m = [];
        if (typeof data === 'object')
            data.forEach((s) =>
                Object.keys(s).map((k) => { if (k != 'type') m.push(`${k} : ${s[k]}`); })
            )
        else m[0] = data;

        return m.join("<br />");
    },


    hasClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) return true;
        else return false;
    },
    setClientData: async (name, value) => {
        if (typeof (Storage) !== 'undefined') {
            localStorage.setItem(name, value);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    getClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            return localStorage.getItem(name);
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    removeClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            localStorage.removeItem(name);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },

    is_mobile: () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return true;//"Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return true;//"Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;//"iOS";
        }

        return false;
    },

}

G.token = (G.hasClientData("AUTH_TOKEN")) ? G.getClientData("AUTH_TOKEN") : "";


axios.interceptors.request.use(req => {
    NProgress.start();
    return req;
}, (error) => {
    NProgress.done();
    return error;
});


axios.interceptors.response.use(response => {
    NProgress.done();
    if (response === undefined) {
        console.log('Server Connection Error !');
        return Promise.reject(response);
    }
    return response;
}, (error) => {
    NProgress.done();
    if (error.response === undefined) {
        console.log('Server Connection Error !');
        return Promise.reject(error);
    }
    else if (error.response.status == 0 && error.code == 'ERR_NETWORK') {
        console.log(`${error.message}`);
        return Promise.reject(error);
    }
    else if (error.response.status == 422 && error.code == 'ERR_BAD_REQUEST') {
        console.log({ msg: G.getErrorMessages(error.response.data.detail) });
        return Promise.reject(error);
    }
    else if (error.response.status == 408) {
        G.setClientData("AUTH_TOKEN", '');
        router.go();
        console.log(error.response.data.detail);
        return Promise.reject(error);
    }
    else
        return error;
});


export const ajax = async (opt = null) => {
    if (opt != null && opt.url !== undefined && opt.url != "")
        await axios({
            baseURL: G.ajaxBaseUrl,
            url: opt.url,
            method: opt.method || "get",
            data: opt.data || {},
            responseType: opt.responseType ? opt.responseType : null,
            headers: opt.header || {
                'X-Sintez-QR-Data': 'sintez_az_qr_code_gen_data',
                'Content-Type': opt && opt.content_type ? opt.content_type : 'application/x-www-form-urlencoded',
                // "Authorization": opt && opt.token ? `Bearer ${opt.token}` : G.getClientData("AUTH_TOKEN"),
            },
            withCredentials: false,
            // credentials: 'same-origin',
            // crossDomain: true,
        }).then(async (response) => {
            if ( response.status == 200 ){
                if (opt.success !== undefined && opt.success != null){
                    await opt.success(response);
                }
            }
            else{
                if (opt.error !== undefined && opt.error != null){
                    await opt.error(response);
                }
                else if( response.response != undefined && response.response.status != 200 ){
                    console.log({msg: response.response.data.detail});
                }
                else{
                    console.log({msg: response.data.detail});
                }
            }
        }).catch(async (error) => {
            let resp = error.response;
            if (opt.error !== undefined && opt.error != null && resp !== undefined){
                await opt.error(error);
            }
            else if (resp && resp.data){
                console.log({msg: G.getErrorMessages(resp.data.detail)});
            }
        });
};
