<template>
    <div class="container p-3 mt-sm-5 border">

        <div class="row text-start mb-5 d-flex align-items-center">
            <div class="col-12 col-sm-4">
                <div class="row d-flex align-items-center">
                    <label class="form-label col-3 text-nowrap">Faylın tipi: </label>
                    <div class="col-6">
                        <select class="form-select" v-model="QRT.type">
                            <option value="png" selected>PNG</option>
                            <option value="svg">SVG</option>
                            <option value="pdf">PDF</option>
                            <!-- <option value="eps">EPS</option> -->
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="row d-flex align-items-center">
                    <label class="form-label col-5 text-nowrap">QR kodun rəngi: </label>
                    <div class="col-3">
                        <input type="color" v-model="QRT.color" class="form-control form-control-color" />
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <label class="form-label me-3">Ölçüsü: </label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @change="qr_size_change('small')" checked />
                    <label class="form-check-label" for="flexRadioDefault1">Small</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" @change="qr_size_change('medium')" />
                    <label class="form-check-label" for="flexRadioDefault2">Medium</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" @change="qr_size_change('big')" />
                    <label class="form-check-label" for="flexRadioDefault3">Big</label>
                </div>
            </div>
        </div>

        <hr>

        <div class="row mt-4">
            <div class="col-12 col-sm-8">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="url-tab" data-bs-toggle="tab" data-bs-target="#url-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                            <i class="fas fa-link me-2"></i> URL
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="text-tab" data-bs-toggle="tab" data-bs-target="#text-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                            <i class="fas fa-bars me-2"></i> Mətn
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="wifi-tab" data-bs-toggle="tab" data-bs-target="#wifi-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
                            <i class="fas fa-wifi me-2"></i> WiFi
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
                            <i class="far fa-id-badge me-2"></i> Contact
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">

                    <div class="tab-pane fade show active" id="url-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                        <div class="row">
                            <div class="col-12">
                                <textarea v-model="QRT.sUrl" autofocus="" class="form-control" placeholder="Saytın ünvanını daxil edin" rows="6"></textarea>
                            </div>
                            <div class="col-12 mt-3">
                                <button type="button" class="btn btn-success" @click="genQR('sUrl')"><i class="fas fa-qrcode me-2"></i>QR Code Hazırla</button>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="text-tab-pane" role="tabpanel" aria-labelledby="text-tab" tabindex="0">
                        <div class="form-group row">
                            <div class="col-12">
                                <textarea v-model="QRT.sText" autofocus="" class="form-control" placeholder="Mətni daxil edin" rows="6"></textarea>
                            </div>
                            <div class="col-12 mt-3">
                                <button type="button" class="btn btn-success" @click="genQR('sText')"><i class="fas fa-qrcode me-2"></i>QR Code Hazırla</button>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="wifi-tab-pane" role="tabpanel" aria-labelledby="wifi-tab" tabindex="0">
                        <div class="form-group text-start">
                            <div class="row mb-3">
                                <label class="form-label me-2 col-2 text-nowrap">Şəbəkə adı ( SSID ): </label>
                                <div class="col-7">
                                    <input type="text" v-model="QRT.ssid" class="form-control" />
                                </div>
                                <div class="col-2">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="ssidHidden" v-model="QRT.ssid_hiden" />
                                        <label class="form-check-label" for="ssidHidden">Gizli</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="form-label me-2">Şifrə: </label>
                                    <input type="text" v-model="QRT.password" class="form-control" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="form-label me-3">Təhlükəsizlik: </label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @change="wifi_enc_change('none')" />
                                        <label class="form-check-label" for="flexRadioDefault1">None</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" @change="wifi_enc_change('WPA')" checked />
                                        <label class="form-check-label" for="flexRadioDefault2">WPA / WPA2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @change="wifi_enc_change('WEP')" />
                                        <label class="form-check-label" for="flexRadioDefault1">WEP</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-center">
                                <div class="col-12 mt-3">
                                    <button type="button" class="btn btn-success" @click="genQRWiFi"><i class="fas fa-qrcode me-2"></i>QR Code Hazırla</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                        <div class="form-group qr-form">
                            <!-- <h2 class="form-header">vCard QR Code</h2> -->
                            <!-- <form novalidate="" class="ng-pristine ng-valid ng-touched"> -->
                            <div class="row">
                                <label for="firstname" class="col-sm-3 form-label">Ad:</label>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="text" v-model="QRT.name" class="form-control" placeholder="Ad">
                                        </div>
                                        <div class="col-6">
                                            <input type="text" v-model="QRT.surname" class="form-control" placeholder="Soyad">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label for="mobileNumber" class="col-sm-3 form-label">Əlaqə:</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="QRT.phone" class="form-control" placeholder="Mobil">
                                </div>
                            </div>
                            <div class="row">
                                <label for="phoneNumber" class="col-sm-3 form-label"></label>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="text" v-model="QRT.homephone" class="form-control" placeholder="Tel">
                                        </div>
                                        <div class="col-6">
                                            <input type="text" v-model="QRT.fax" class="form-control" placeholder="Fax">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <label for="email" class="col-sm-3 form-label">Email:</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="QRT.email" class="form-control" placeholder="sizin@email.az">
                                </div>
                            </div>
                            <div class="row">
                                <label for="company" class="col-sm-3 form-label">Şirkət:</label>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="text" v-model="QRT.org" class="form-control" placeholder="Şirkət">
                                        </div>
                                        <div class="col-6">
                                            <input type="text" v-model="QRT.job" class="form-control" placeholder="Vəzifə">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label for="state" class="col-sm-3 form-label">Ölkə:</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="QRT.country" class="form-control" placeholder="Ölkə">
                                </div>
                            </div>
                            <div class="row">
                                <label for="city" class="col-sm-3 form-label">Şəhər:</label>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-7">
                                            <input type="text" v-model="QRT.city" class="form-control" placeholder="Şəhər">
                                        </div>
                                        <div class="col-5">
                                            <input type="text" v-model="QRT.zipcode" class="form-control" placeholder="Poçt indeksi">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label for="street" class="col-sm-3 form-label">Küçə:</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="QRT.street" class="form-control" placeholder="Küçə">
                                </div>
                            </div>
                            <!-- <div class="row">
                                <label for="country" class="col-sm-3 form-label">Country:</label>
                                <div class="col-sm-9">
                                    <input type="text" formcontrolname="country" id="country" class="form-control">
                                </div>
                            </div> -->
                            <div class="row">
                                <label for="website" class="col-sm-3 form-label">Web səhifə:</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="QRT.url" class="form-control" placeholder="www.sizin-səhifə.az">
                                </div>
                            </div>
                            <div class="mt-3">
                                <button type="button" class="btn btn-success" @click="genQRContact"><i class="fas fa-qrcode me-2"></i>QR Code Hazırla</button>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">...</div> -->
                </div>
            </div>

            <div class="col-12 col-sm-4 d-flex flex-column justify-content-center align-items-center">
                <img v-if="QRT.type != 'pdf'" :src="imageFile" alt="" style="width: 300px; height: 300px;" />
                <a v-else-if="pdf_link != ''" :href="pdf_link" target="blank">Sizin PDF faylınız</a>

                <!-- <a v-if="show_download_btn" class="btn btn-primary" target="_blank" download="test.png" :href="imageFile">Download</a> -->
                <!-- <a v-if="show_download_btn" class="btn btn-primary" :href="imageFile">Download</a> -->
                <a v-if="show_download_btn" class="btn btn-primary" @click="download_btn">Download</a>
            </div>
        </div>

    </div>

    <Footer />

</template>

<script>
    import {ref, reactive} from 'vue';

    import Footer from '@/components/FooterSimple';

    import {G, ajax} from '@/_common';

    export default {
        name: "HomeView",
        components: {
            Footer,
        },
        setup() {
            const imageFile = ref('/assets/images/favicon.png');
            const pdf_link = ref('');
            const show_download_btn = ref(false);

            const QRT = reactive({
                sUrl: '',
                sText: '',
                size: 10,
                type: 'png',
                color: '#000000',
                ssid: '',
                password: '',
                security: 'WPA',
                ssid_hiden: false,


                name: '',
                surname: '',
                displayname: '',
                email: '',
                url: '',
                phone: '',

                fax: '',
                country: '',
                city: '',
                street: '',
                zipcode: '',
                org: '',
                job: '',
                homephone: ''
            });

            const qr_size_change = (size) => {
                QRT.size = size == 'small' ? 10 : (size == 'medium' ? 15 : 20);
            };

            const wifi_enc_change = (sec) => {
                QRT.security = sec;
            };

            const initButtons = () => {
                show_download_btn.value = false;
            };

            const download_btn = async () => {
                if( QRT.type != 'pdf' ){
                    downloadImage(imageFile.value)
                }
            };

            const downloadImage = async (imageSrc)  => {
                const image = await fetch(imageSrc)
                const imageBlog = await image.blob()
                const imageURL = URL.createObjectURL(imageBlog)

                let fn = imageFile.value.toString().split('/').slice(-1)[0].split('?')[0];

                const link = document.createElement('a')
                link.href = imageURL
                link.download = fn
                link.rel = 'noopener'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            };


            const genQR = async (sTxt) => {
                initButtons();

                let qdata = {};
                if( QRT[sTxt] != '' ){
                    if( QRT[sTxt] )
                        qdata.content = QRT[sTxt];
                    if( QRT.type )
                        qdata.type = QRT.type;
                    if( QRT.color )
                        qdata.color = QRT.color;
                    if( QRT.size )
                        qdata.size = QRT.size;

                    await ajax({
                        url: G.ajaxBaseUrl + '/text', //store.getters.getRoles(),
                        method: 'post',
                        content_type: 'application/json; charset=UTF-8',
                        data: qdata,
                        success: (x) => {
                            let res = x.data || null;
                            if ( x.status == 200 && res) {
                                if( QRT.type == 'pdf' ){
                                    pdf_link.value = res.file;
                                }
                                else{
                                    imageFile.value = res.file;
                                    show_download_btn.value = true;
                                }
                            }
                            else{
                                imageFile.value = '/assets/images/favicon.png';
                                console.log(res.detail);
                                show_download_btn.value = false;
                            }
                        },
                        error: (x) => {
                            show_download_btn.value = false;
                            var res = x.response || {};
                            // if( res.status == 422 ){
                                imageFile.value = '/assets/images/favicon.png';
                                console.log(G.getErrorMessages(res.data.detail));
                            // }
                        }
                    });
                }
            };

            const genQRWiFi = async () => {
                initButtons();

                let qdata = {};
                if( QRT.ssid )
                    qdata.ssid = QRT.ssid;
                if( QRT.password )
                    qdata.password = QRT.password;
                if( QRT.security )
                    qdata.security = QRT.security;
                if( QRT.ssid_hiden )
                    qdata.ssid_hiden = QRT.ssid_hiden ? 1 : 0;
                if( QRT.type )
                    qdata.type = QRT.type;
                if( QRT.color )
                    qdata.color = QRT.color;
                if( QRT.size )
                    qdata.size = QRT.size;

                await ajax({
                    url: G.ajaxBaseUrl + '/wifi', //store.getters.getRoles(),
                    method: 'post',
                    content_type: 'application/json; charset=UTF-8',
                    data: qdata,
                    success: (x) => {
                        let res = x.data || null;
                        if ( x.status == 200 && res) {
                            if( QRT.type == 'pdf' ){
                                pdf_link.value = res.file;
                            }
                            else{
                                imageFile.value = res.file;
                                show_download_btn.value = true;
                            }
                        }
                        else{
                            imageFile.value = '/assets/images/favicon.png';
                            console.log(res.detail);
                            show_download_btn.value = false;
                        }
                    },
                    error: (x) => {
                        show_download_btn.value = false;
                        var res = x.response || {};
                        // if( res.status == 422 ){
                            imageFile.value = '/assets/images/favicon.png';
                            console.log(G.getErrorMessages(res.data.detail));
                        // }
                    }
                });
            };

            const genQRContact = async () => {
                initButtons();

                let qdata = {};
                if( QRT.name )
                    qdata.name = QRT.name;
                if( QRT.surname )
                    qdata.name += ';' + QRT.surname;
                if( QRT.name && QRT.surname )
                    qdata.displayname = QRT.name + ' ' + QRT.surname;
                if( QRT.email )
                    qdata.email = QRT.email;
                if( QRT.url )
                    qdata.url = QRT.url;
                if( QRT.phone )
                    qdata.phone = QRT.phone;
                if( QRT.fax )
                    qdata.fax = QRT.fax;

                if( QRT.street )
                    qdata.street = QRT.street;
                if( QRT.city )
                    qdata.city = QRT.city;
                if( QRT.country )
                    qdata.country = QRT.country;
                if( QRT.zipcode )
                    qdata.zipcode = QRT.zipcode;
                if( QRT.org )
                    qdata.org = QRT.org;
                if( QRT.job )
                    qdata.org += ', ' + QRT.job;
                if( QRT.homephone )
                    qdata.homephone = QRT.homephone;

                if( QRT.type )
                    qdata.type = QRT.type;
                if( QRT.color )
                    qdata.color = QRT.color;
                if( QRT.size )
                    qdata.size = QRT.size;

                await ajax({
                    url: G.ajaxBaseUrl + '/contact', //store.getters.getRoles(),
                    method: 'post',
                    content_type: 'application/json; charset=UTF-8',
                    data: qdata,
                    success: (x) => {
                        let res = x.data || null;
                        if ( x.status == 200 && res) {
                            if( QRT.type == 'pdf' ){
                                pdf_link.value = res.file;
                            }
                            else{
                                imageFile.value = res.file;
                                show_download_btn.value = true;
                            }
                        }
                        else{
                            imageFile.value = '/assets/images/favicon.png';
                            console.log(res.detail);
                            show_download_btn.value = false;
                        }
                    },
                    error: (x) => {
                        show_download_btn.value = false;
                        var res = x.response || {};
                        // if( res.status == 422 ){
                            imageFile.value = '/assets/images/favicon.png';
                            console.log(G.getErrorMessages(res.data.detail));
                        // }
                    }
                });
            };

            return {
                QRT,
                imageFile,
                pdf_link,
                genQR,
                genQRWiFi,
                genQRContact,
                qr_size_change,
                wifi_enc_change,
                show_download_btn,
                download_btn,
            };
        },
    };
</script>


<style lang="scss">
.tab-pane{
    padding-top: 20px;
}
.form-label{
    margin-bottom: 0px;
}
.qr-form > .row{
    margin-bottom: 10px;
}
.footerClass{
    color: #ffffff;
    text-decoration: none;
}
</style>